import React, { useMemo } from 'react';

import { Grid } from '@material-ui/core';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { microservicesClient } from 'src/apollo/client';
import { getOrgId } from 'src/apollo/local-state';
import AdminTable, { DataCols } from 'src/components/admin-table/admin-table';
import EmptyState from 'src/components/empty-state/empty-state';
import Panel from 'src/components/panel/panel';
import { H4 } from 'src/components/typography/typography';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import { Memberships_memberships } from 'src/graphql-types/saas/Memberships';
import { capitalize } from 'src/utils/helper/rankings';
import ROUTES from 'src/utils/routes';
import { retrieveToken } from 'src/utils/storage/local-storage';

import { useSaaSMemberships } from './saas-memberships.api';

export function SaaSMemberships() {
  const { t: tl } = useTranslation();

  const { memberships, loadingMemberships } = useSaaSMemberships({
    filter: {},
    options: {
      skip: !retrieveToken(),
    },
  });

  const cols: DataCols<Memberships_memberships> = useMemo(
    () => [
      { key: 'name', title: tl('name'), getValue: (m) => m.name },
      { key: 'code', title: tl('code'), getValue: (m) => m.code },
      {
        key: 'type',
        title: tl('type'),
        getValue: (m) => capitalize(m?.type?.toLowerCase()),
      },
    ],
    [tl],
  );

  return (
    <PageMaxWidth>
      <Panel>
        {!loadingMemberships && memberships?.length === 0 ? (
          <EmptyState title="There are no memberships" icon="lg-empty-state" />
        ) : (
          <>
            <Grid container alignItems="center">
              <H4 spacing={{ margins: { xs: 'bottom' } }}>{tl('memberships')}</H4>
            </Grid>
            <AdminTable
              columns={cols}
              data={memberships}
              loading={loadingMemberships}
              onRowClick={(row) => navigate(`${ROUTES.MEMBERSHIPS}/${row.id}`)}
            />
          </>
        )}
      </Panel>
    </PageMaxWidth>
  );
}
