import { QueryHookOptions, useQuery } from '@apollo/client';
import { meshGatewayClient } from 'src/apollo/client';
import { Memberships, MembershipsVariables } from 'src/graphql-types/saas/Memberships';

import { GET_MEMBERSHIPS } from './saas-memberships.queries';

interface UseSaaSMembershipsProps {
  options?: Omit<QueryHookOptions<Memberships, MembershipsVariables>, 'variables' | 'client'>;
  filter: MembershipsVariables['filter'];
}

export function useSaaSMemberships(props?: UseSaaSMembershipsProps) {
  const { options = {}, filter } = props || {};
  const query = useQuery(GET_MEMBERSHIPS, {
    client: meshGatewayClient,
    variables: { filter },
    ...options,
  });
  return {
    ...query,
    memberships: query?.data?.memberships,
    loadingMemberships: query.loading,
  };
}
